import { defineStore, storeToRefs } from 'pinia'
import axios from 'axios'
import db from '@/db'
import { useLocalStore } from './LocalStore'
import { useCalendarDateStore } from './CalendarDateStore'
import { useCategoriesColorStore } from './CategoriesColorStore'

const api = import.meta.env.VITE_API

const categoriesColorStore = useCategoriesColorStore()

export const useCalendarCategoriesAndActivitiesStore = defineStore(
  'calendarCategoriesAndActivities',
  {
    state: () => ({
      categoriesList: [],
      activitiesList: [],
      usageYears: new Set(["2024","2025"]),
      loaded: false,
    }),

    actions: {
      async fetchData() {
        const { currentUser } = storeToRefs(useLocalStore())
        const { calendarYear } = storeToRefs(useCalendarDateStore())

        try {
          await checkApiStatus()

          const response = await axios.get(`${api}/category/list`)

          const categories = response.data

          await Promise.all(
            categories.map((category) =>
              processCategory(category, currentUser.value.token, null, calendarYear.value, this)
            )  
          )

          await this.loadData()
        } catch (error) {
          console.error('Error fetching categories and activities:', error)
        }
      },

      async updateData(lastSynch) {
        const { currentUser } = storeToRefs(useLocalStore())
        const { calendarYear } = storeToRefs(useCalendarDateStore())

        try {
          await checkApiStatus()

          const response = await axios.get(`${api}/remote/category/synch/list`, { params: { lastSynch } })

          const categories = response.data

          await Promise.all(
            categories.map((category) =>
              processCategory(category, currentUser.value.token, lastSynch, calendarYear.value, this)
            )
          )

          await this.loadData()
        } catch (error) {
          console.error('Error fetching updated categories:', error)
        }
      },

      async loadData() {
        try {
          const categories = await db.categories.toArray()
          
          if (categories.length === 0) return

          const activities = await db.activities.toArray()

          const activitiesListAux = categories.map((cat) =>
            activities.filter((activity) => activity.categoryCode === cat.code)
          )

          this.categoriesList = categories
          this.activitiesList = activitiesListAux
          this.loaded = true
        } catch (error) {
          console.error('Error loading activities and categories:', error)
        }
      },
    },
  }
)

async function checkApiStatus() {
  await axios.get(`${api}/status`, { timeout: 2000 })
}

async function processCategory(category, token, lastSynch = null, calendarYear, store) {
  if (!category.active) {
    await db.categories.delete(category._id)
    return
  }

  const colorBase = categoriesColorStore.getColorBase(category.branch)

  await db.categories.put({
    code: category._id,
    active: category.active,
    author: category.author,
    name: category.name,
    icon: category.icon,
    branch: category.branch,
    color: colorBase,
    created: category.created,
    changed: category.changed
  })

  const activities = lastSynch ===  null
    ? await fetchActivities(category._id, token)
    : await fetchSynchedActivities(category._id, lastSynch, token)

  if (activities.length === 0) {
    await db.categories.delete(category._id)
  } else {
    await Promise.all(
      activities.map((activity) =>
        processActivity(activity, category._id, calendarYear, store)
      )
    )
  }
}

async function fetchActivities(categoryId, token) {
  try {
    const response = await axios.get(`${api}/activity/${categoryId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    return response.data
  } catch (error) {
    console.error(`Error fetching activities for category: ${categoryId}`, error)
    return []
  }
}

async function fetchSynchedActivities(categoryId, lastSynch, token) {
  try {
    const response = await axios.get(
      `${api}/remote/activity/synch/${categoryId}`,
      {
        params: { lastSynch },
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    return response.data
  } catch (error) {
    console.error(`Error fetching activities for category: ${categoryId}`, error)
    return []
  }
}

async function processActivity(activity, categoryId, calendarYear, store) {
  if (
    activity.active &&
    activity.recommended.some((rec) => Number(rec.year) === calendarYear)
  ) {
    activity.recommended.forEach(rec => {
      store.usageYears.add(rec.year)
    })

    await db.activities.put({
      code: activity._id,
      active: activity.active,
      author: activity.author,
      name: activity.name,
      description: activity.description,
      recommended: activity.recommended,
      categoryCode: categoryId,
      created: activity.created,
      changed: activity.changed,
    })
  } else {
    await db.activities.delete(activity._id)
  }
}

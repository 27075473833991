<template>
  <v-row class="ma-0 pa-0" justify="center">
    <v-toolbar color="teal" justify="start" density="comfortable" elevation="3" flat>
      <!-- Botão Voltar -->
      <v-btn class="text-white" icon="mdi-arrow-left" @click="$router.push('/')"></v-btn>

      <!-- Título do Calendário (aparece somente em telas maiores) -->
      <CalendarTitle v-if="!smAndDown" class="ml-2" />

      <v-spacer v-if="smAndDown" />

      <!-- Botão de Ajuste -->
      <CalendarSettingsDialog />

      <!-- Botão de Sincronização -->
      <v-btn
        density="comfortable"
        size="large"
        icon="mdi-sync"
        @click="synch = true"
      ></v-btn>

      <!-- Botão de Exportação Excel -->
      <v-btn
        density="comfortable"
        size="large"
        icon="mdi-file-excel-outline"
        @click="importexport = true"
      ></v-btn>

      <v-spacer v-if="!smAndDown" />

      <!-- Dialog de Relatório de Atividades -->
      <ActivitiesReportDialog />

      <!-- User Profile -->
      <div class="d-flex align-center" style="margin-left: auto">
        <UserProfile />
      </div>
    </v-toolbar>

    <!-- Progress Bar -->
    <v-progress-linear v-if="synch" color="purple" indeterminate></v-progress-linear>

    <!-- Título do Calendário (aparece somente em telas pequenas) -->
    <CalendarTitle v-if="smAndDown" />

    <!-- Dialog de Importação/Exportação -->
    <ExcelExportDialog v-model="importexport" />
  </v-row>
</template>

<script setup>
import { ref, watch } from "vue";
import { useDisplay } from "vuetify";
import ActivitiesReportDialog from "../components/ActivitiesReportDialog.vue";
import CalendarTitle from "../components/CalendarTitle.vue";
import CalendarSettingsDialog from "../components/CalendarSettingsDialog.vue";
import ExcelExportDialog from "../components/ExcelExportDialog.vue";
import UserProfile from "../components/UserProfile.vue";
import { useLocalStore } from "@/stores/LocalStore";
import { storeToRefs } from "pinia";

const { xs, sm, smAndDown } = useDisplay();
const importexport = ref(false);

const localStore = useLocalStore();
const { synch } = storeToRefs(localStore);
</script>

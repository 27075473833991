<template>
  <v-row align="start" no-gutters>
    <v-col cols="12" align-center>
      <v-dialog v-model="introCalendarForm" width="1000px" persistent>
        <v-card>
          <v-card-text>
            <p class="text-h5 text-center">Lorem Ipsum</p>
            <br />
            <p class="text-justify">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla facilisi. Sed
              at justo eget velit pretium sagittis. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Donec bibendum, elit
              sit amet convallis faucibus, justo lectus dictum mauris, vel gravida lectus
              turpis ac metus. Integer euismod, orci eget consectetur feugiat, eros lectus
              tincidunt arcu, a consectetur mauris metus nec lacus.
            </p>
            <br />
            <p class="text-justify">
              Fusce euismod eros sed ex ultricies, sed dignissim ligula fermentum. Morbi
              ac orci nec felis dapibus scelerisque non eu est. Quisque a quam sed elit
              condimentum rhoncus. Aliquam erat volutpat. Vestibulum ante ipsum primis in
              faucibus orci luctus et ultrices posuere cubilia curae; Nam auctor efficitur
              tortor, in volutpat eros tincidunt ac.
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="#009688"
              class="white--text"
              prepend-icon="mdi-check-underline"
              @click="showWizard"
              >Entendi</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-dialog
      v-model="wizard"
      width="500px"
      :fullscreen="$vuetify.display.xsOnly"
      persistent
    >
      <CalendarWizard @close="hideWizard" />
    </v-dialog>
  </v-row>
</template>

<script setup>
import CalendarWizard from "@/components/CalendarWizard.vue";
import { ref } from "vue";
import db from "@/db";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { useCalendarEventsStore } from "@/stores/CalendarEventsStore";
import { useCalendarRegisterStore } from "@/stores/CalendarRegisterStore";

const calendarRegisterStore = useCalendarRegisterStore();
const calendarEventsStore = useCalendarEventsStore();
const calendarNameStore = useCalendarNameStore();

const introCalendarForm = ref(true);
const wizard = ref(false);

const showWizard = async () => {
  const lastAccessedFarm = ref();
  const farms = ref();
  const farm = ref();
  introCalendarForm.value = false;

  await db.lastAccessed
    .toArray()
    .then((lastAccessed) => {
      if (!lastAccessed.length || !lastAccessed[0].farm) {
        wizard.value = true;
      } else {
        lastAccessedFarm.value = lastAccessed[0].farm;
      }
    })
    .catch((error) => {
      console.error("Error in beforeMount:", error);
    });

  await db.farm
    .orderBy("name")
    .toArray()
    .then((farmsData) => {
      farms.value = farmsData;
      farm.value = farms.value.find((f) => f.code === lastAccessedFarm.value) || null;
    })
    .catch((error) => {
      console.error("Error loading farms:", error);
    });

  await calendarNameStore.changeName(farm.value);
  await calendarRegisterStore.loadData();
  await calendarEventsStore.getEvents();
};

const hideWizard = () => {
  wizard.value = false;
};
</script>

import { defineStore } from 'pinia'

export const useCategoriesColorStore = defineStore('categoriesColor', {
  state: () => ({
    greenPallete: ["#008000", "#009400", "#00A800", "#00BB00", "#00CF00", "#00E300", "#00F700", "#1FFF1F", "#38FF38", "#47FF47"],
    greenTone: 0,
    redPallete: ["#FF2C2C", "#FF4141", "#FF5555", "#FF6A6A", "#FF7E7E"],
    redTone: 0,
  }),
  actions: {
    // Get color base based on category branch
    getColorBase (branch) {
      if (branch === 'vegetal') {
        this.greenTone++;
        return this.greenPallete[this.greenTone]; // Green
      } else if (branch === 'animal') {
        this.redTone++;
        return this.redPallete[this.redTone];// Red
      } else {
        return '#0000FF'; // Blue (default for other branches)
      }
    },
  }
});

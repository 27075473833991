<template>
  <div v-if="smAndDown" style="width: 100vw">
    <!-- Telas pequenas -->
    <v-row justify="center">
      <v-col cols="12" sm="11" class="px-4">
        <v-btn
          block
          density="comfortable"
          size="x-large"
          prepend-icon="mdi-map-marker"
          append-icon="mdi-menu-down"
          class="mt-1"
          @click.stop="showWizard"
        >
          <div>
            <v-list-item-title>
              {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ subtitle }}
            </v-list-item-subtitle>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>

  <div v-else>
    <!-- Telas grandes -->
    <v-row align="center" justify="start" class="pa-0 ma-0">
      <v-col align="start" class="d-flex align-center pa-0 ma-0">
        <v-btn
          block
          variant="tonal"
          color="teal-lighten-5"
          size="x-large"
          class="pl-2 pr-3"
          @click.stop="showWizard"
        >
          <v-icon color="white" class="mr-1"> mdi-map-marker </v-icon>
          <div>
            <v-list-item-title class="text-start text-white">
              {{ title }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ subtitle }}
            </v-list-item-subtitle>
          </div>
        </v-btn>
      </v-col>
    </v-row>
  </div>

  <v-dialog
    v-model="wizard"
    width="500px"
    :fullscreen="$vuetify.display.xsOnly"
    persistent
  >
    <CalendarWizard @close="hideWizard" />
  </v-dialog>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { ref, onBeforeMount } from "vue";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { storeToRefs } from "pinia";
import CalendarWizard from "@/components/CalendarWizard.vue";

const calendarNameStore = useCalendarNameStore();
const { title, subtitle } = storeToRefs(calendarNameStore);

const { xs, smAndDown } = useDisplay();
const wizard = ref(false);

const showWizard = () => {
  wizard.value = true;
};

const hideWizard = () => {
  wizard.value = false;
};

onBeforeMount(() => {
  calendarNameStore.loadName();
});
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="newBatchDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h5">Novo Lote</span>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="batchName"
                  variant="outlined"
                  hide-details
                  label="Nome do lote"
                  :rules="fieldRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedMonth"
                  :items="months"
                  item-title="name"
                  item-value="value"
                  variant="outlined"
                  hide-details
                  label="Mês de nascimento"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="selectedYear"
                  :items="years"
                  variant="outlined"
                  hide-details
                  label="Ano de nascimento"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="selectedQualificator"
                  variant="outlined"
                  :disabled="!batchQualificatorEnable"
                  hide-details
                  :items="selectedQualificators"
                  label="Categoria animal"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            variant="text"
            @click="hideNewBatchDialog()"
            prepend-icon="mdi-cancel"
          >
            <span>Cancelar</span>
          </v-btn>
          <v-btn
            color="#009688"
            variant="text"
            @click="addNewBatch()"
            prepend-icon="mdi-file-document-plus-outline"
          >
            Registrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CalendarSnackbar v-model="eventSnackbar" :text="snackbarText" />
  </v-row>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useDate } from "vuetify";
import { v4 as uuid } from "uuid";
import db from "@/db";
import CalendarSnackbar from "../components/CalendarSnackbar.vue";
import { useCalendarRegisterStore } from "@/stores/CalendarRegisterStore";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { storeToRefs } from "pinia";

const calendarNameStore = useCalendarNameStore();
const { farm } = storeToRefs(calendarNameStore);

const calendarRegisterStore = useCalendarRegisterStore();

const emit = defineEmits(["refresh"]);

const newBatchDialog = defineModel();
const batchName = ref("");
const newBatchMenu = ref(false);
const selectedQualificators = ref([]);
const selectedQualificator = ref("");
const batchQualificatorEnable = ref(false);

const eventSnackbar = ref(false);
const snackbarText = ref("");

const fieldRules = [(v) => !!v || "Campo obrigatório"];

const selectedMonth = ref(null);
const selectedYear = ref(null);

const months = ref([
  { name: "Janeiro", value: 1 },
  { name: "Fevereiro", value: 2 },
  { name: "Março", value: 3 },
  { name: "Abril", value: 4 },
  { name: "Maio", value: 5 },
  { name: "Junho", value: 6 },
  { name: "Julho", value: 7 },
  { name: "Agosto", value: 8 },
  { name: "Setembro", value: 9 },
  { name: "Outubro", value: 10 },
  { name: "Novembro", value: 11 },
  { name: "Dezembro", value: 12 },
]);
const years = Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i);

const newBatchDate = computed(() => {
  if (selectedYear.value && selectedMonth.value) {
    return new Date(selectedYear.value, selectedMonth.value - 1, 1); // Mês no JS começa em 0
  }
  return null;
});

const formatDate = (date) => {
  const adapter = useDate();
  if (!date) return null;
  return adapter.format(date, "keyboardDate");
};

const addNewBatch = () => {
  const b = {
    code: uuid(),
    branch: "animal",
    name: batchName.value,
    dateOfBirth: new Date(newBatchDate.value),
    qualificator: selectedQualificator.value,
    active: true,
    visible: true,
    farmCode: farm.value.code,
    created: new Date(),
    changed: new Date(),
  };

  db.registers
    .where("name")
    .equals(b.name)
    .first()
    .then((existingBatch) => {
      if (existingBatch) {
        newBatchDialog.value = false;
        batchName.value = "";
        newBatchDate.value = null;
        selectedQualificator.value = "";
        snackbarText.value = "Lote já existe!";
        eventSnackbar.value = true;
        console.log("Batch already exists");
      } else {
        db.registers
          .add(b)
          .then(() => {
            calendarRegisterStore.loadData();
            emit("refresh", batchName.value);
            batchName.value = "";
            newBatchDialog.value = false;
            newBatchDate.value = null;
            selectedQualificator.value = "";
            snackbarText.value = "Lote criado com sucesso";
            eventSnackbar.value = true;
          })
          .catch((error) => {
            console.log("Error adding batch:", error);
          });
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const hideNewBatchDialog = () => {
  newBatchDialog.value = false;
  batchName.value = null;
  selectedMonth.value = null;
  selectedYear.value = null;
};

const batchQualificator = () => {
  const batchDate = new Date(newBatchDate.value);
  const currentDate = new Date();

  const batchAge =
    (currentDate.getFullYear() - batchDate.getFullYear()) * 12 +
    (currentDate.getMonth() - batchDate.getMonth());

  if (batchAge > 12) {
    selectedQualificators.value = qualificators.slice(0, 3);
    batchQualificatorEnable.value = true;
  } else if (batchAge > 9 && batchAge <= 12) {
    selectedQualificators.value = qualificators.slice(6, 7);
    batchQualificatorEnable.value = false;
  } else if (batchAge > 6 && batchAge <= 9) {
    selectedQualificators.value = qualificators.slice(5, 6);
    batchQualificatorEnable.value = false;
  } else if (batchAge > 3 && batchAge <= 6) {
    selectedQualificators.value = qualificators.slice(4, 5);
    batchQualificatorEnable.value = false;
  } else {
    selectedQualificators.value = qualificators.slice(3, 4);
    batchQualificatorEnable.value = false;
  }

  selectedQualificator.value = selectedQualificators.value[0];
};

watch(newBatchDate, () => {
  batchQualificator();
});

const qualificators = [
  "Sobreano",
  "Touro",
  "Matriz",
  "Recém-nascidos",
  "Maternal",
  "Desmama",
  "Pós-desmama",
];
</script>

import { defineStore } from 'pinia'
import db from '@/db'
import { useCalendarDateStore } from './CalendarDateStore'
import { useCalendarNameStore } from '@/stores/CalendarNameStore'
import { storeToRefs } from 'pinia'

export const useCalendarEventsStore = defineStore('calendarEvents', {
  state: () => ({
    events: []
  }),
  actions: {
    async getEvents() {
      const calendarNameStore = useCalendarNameStore()
      const { farm } = storeToRefs(calendarNameStore)

      const calendarDateStore = useCalendarDateStore()
      const { calendarYear, calendarMonth } = storeToRefs(calendarDateStore)

      const startOfMonth = new Date(calendarYear.value, calendarMonth.value - 1, 1)
      const endOfMonth = new Date(calendarYear.value, calendarMonth.value, 0)

      // Preload categories and activities into maps
      const [categories, activities] = await Promise.all([
        db.categories.toArray(),
        db.activities.toArray()
      ])

      const categoryMap = new Map(categories.map(cat => [cat.code, cat]))
      const activityMap = new Map(activities.map(act => [act.code, act]))

      // Filter events within the date range and from the selected farm
      const events = await db.events
      .filter(event => 
        isEventInDateRange(event, startOfMonth, endOfMonth) && 
        isEventFromFarm(event, farm.value)
      )
      .toArray()

      const newEvents = await Promise.all(
        events.map(async event => {
          const eventDisplay = await getEventDisplay(event)

          const category = categoryMap.get(event.categoryCode)
          const activity = activityMap.get(event.activityCode)

          return {
            id: event.code,
            start: event.start,
            end: event.end,
            title: activity ? activity.name : 'Unknown Activity',
            extendedProps: {
              description: event.description,
              created: event.created,
              changed: event.changed
            },
            color: category ? category.color : 'primary',
            display: eventDisplay,
            allDay: true
          }
        })
      )

      this.events = newEvents
    } 
  }
})

// Helper Functions

function isEventInDateRange(event, startOfMonth, endOfMonth) {
  return (
    (event.start >= startOfMonth && event.start <= endOfMonth) || // Event starts in the month
    (event.end >= startOfMonth && event.end <= endOfMonth) || // Event ends in the month
    (event.start < startOfMonth && event.end > endOfMonth) // Event spans the entire month
  )
}

function isEventFromFarm(event, farm) {
  // Check if farm is provided and is not an empty object
  if (!farm || Object.keys(farm).length === 0) return false

  // Compare properties of event.farm and farm
  return (
    event.farmCode === farm.code
  )
}

async function getEventDisplay(event) {
  const eventRegistersLink = await db.eventRegisterLink.where('eCode').equals(event.code).toArray()
  const registerCodes = eventRegistersLink.map(erl => erl.rCode)

  const registers = await db.registers.where('code').anyOf(registerCodes).toArray()
  return registers.some(register => register.visible) ? 'block' : 'none'
}

async function getRegistersForEvent(eventCode) {
  // Get links of registers related to the event
  const eventRegisterLinks = await db.eventRegisterLink
    .where('eCode')
    .equals(eventCode)
    .toArray()

  // extract the register codes
  const registerCodes = eventRegisterLinks.map(link => link.rCode)

  // Get the rescpective registers
  const registers = await db.registers.where('code').anyOf(registerCodes).toArray()

  // Return the registers names as a string
  const registersNameString = registers.map(register => register.name).join(", ")

  return registersNameString
}

<template>
  <v-card :tile="$vuetify.display.xsOnly" class="d-flex flex-column">
    <v-card-title style="background-color: #009688">
      <span class="headline" style="color: white">Abrir Calendário da fazenda...</span>
    </v-card-title>
    <v-card-text>
      <!-- SELECT FARM -->
      <v-container class="mt-1 pa-0">
        {{ lastAccessedFarmRecoveryCheck }}
        <v-autocomplete
          variant="outlined"
          color="#009688"
          hide-details
          :items="farms"
          item-value="code"
          v-model="farm"
          return-object
          :item-title="
            (f) => {
              if (!f.code) return f.description;

              return f.name + ' (' + f.city + ' - ' + f.state + ')';
            }
          "
          label="Selecione uma fazenda..."
          prepend-icon="mdi-tractor"
          @update:modelValue="showNewFarmForm()"
        >
          <template v-slot:item="{ props }">
            <v-list-item v-bind="props">
              <template v-if="!props.key" v-slot:prepend>
                <v-icon color="#009688"> mdi-plus-box </v-icon>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-container>

      <!-- NEW FARM FORM -->
      <v-divider v-if="showNewFarm" />
      <v-container class="mt-4 pa-0" v-if="showNewFarm">
        <v-card variant="outlined" color="blue-grey lighten-2">
          <v-card-title>Cadastrar Fazenda</v-card-title>
          <v-card-text>
            <v-text-field
              variant="outlined"
              color="#009688"
              label="Nome da Fazenda"
              append-outer-icon="mdi-rename"
              v-model="newFarm.name"
              :counter="20"
            />
            <v-autocomplete
              variant="outlined"
              color="#009688"
              :items="states"
              @change="newFarm.city = null"
              v-model="newFarm.state"
              return-object
              :filter="filterStates"
              item-title="name"
              label="Estado"
              append-outer-icon="mdi-map"
              hide-no-data
            />
            <v-autocomplete
              variant="outlined"
              color="#009688"
              :items="cities"
              v-model="newFarm.city"
              return-object
              :filter="filterCities"
              label="Cidade"
              append-outer-icon="mdi-map-marker"
              hide-no-data
            />
          </v-card-text>
        </v-card>
      </v-container>
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-btn
        color="error"
        text
        @click="
          cancel();
          $emit('close');
        "
        v-if="!$vuetify.display.xsOnly"
        prepend-icon="mdi-cancel"
      >
        Cancelar
      </v-btn>

      <v-spacer v-if="!$vuetify.display.xsOnly" />

      <v-btn
        color="#009688"
        class="white--text"
        depressed
        :disabled="!validateStep()"
        large
        @click="save()"
      >
        Abrir
        <v-icon class="ml-1"> mdi-chevron-right </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import brazil from "@/assets/brazil.json";
import db from "@/db";
import { v4 as uuid } from "uuid";
import { ref, computed, reactive, onBeforeMount } from "vue";
import { useCalendarNameStore } from "@/stores/CalendarNameStore";
import { useCalendarEventsStore } from "@/stores/CalendarEventsStore";
import { useCalendarRegisterStore } from "@/stores/CalendarRegisterStore";

const calendarRegisterStore = useCalendarRegisterStore();
const calendarEventsStore = useCalendarEventsStore();
const calendarNameStore = useCalendarNameStore();

const emit = defineEmits(["close"]);

let farm = ref(null);
const farms = ref([]);
const showNewFarm = ref(false);
const newFarm = reactive({
  name: "",
  city: null,
  state: null,
});
const lastAccessedFarm = ref(null);
const states = ref([]);

const filterStates = (item, query) => {
  if (!item || item === "") return false;

  const textOne = item.name.toLowerCase();
  const textTwo = item.uf.toLowerCase();
  const search = query.toLowerCase();

  return textOne.indexOf(search) > -1 || textTwo.indexOf(search) > -1;
};

const filterCities = (item, query) => {
  if (!item || item === "") return false;

  const textOne = item.toLowerCase();
  const search = query.toLowerCase();

  return textOne.indexOf(search) > -1;
};

const loadFarms = async () => {
  await db.farm
    .orderBy("name")
    .toArray()
    .then((farmsData) => {
      farmsData.unshift({
        code: null,
        description: "Cadastrar uma nova fazenda...",
      });

      farms.value = farmsData;
    })
    .catch((error) => {
      console.error("Error loading farms:", error);
    });
  await db.lastAccessed
    .toArray()
    .then((lastAccess) => {
      if (lastAccess && lastAccess[0]) {
        lastAccessedFarm.value = lastAccess[0].farm;
      }
    })
    .catch((error) => {
      console.error("Error fetching lastAccessed farm: ", error);
    });
};

onBeforeMount(async () => {
  await loadFarms();

  try {
    const lastAccessed = await db.lastAccessed.toArray();
    if (!lastAccessed.length || !lastAccessed[0].farm) {
      farm.value = farms.value[0].description;
      showNewFarm.value = true;
    } else {
      farm.value = farms.value.find((f) => f.code === lastAccessedFarm.value) || null;
    }
  } catch (error) {
    console.error("Error in onBeforeMount: ", error);
  }

  states.value = brazil.states.map((e) => ({ name: e.name, uf: e.uf }));
});

const reload = async () => {
  farm.value = null;
  newFarm.name = "";
  newFarm.city = null;
  newFarm.state = null;
  showNewFarm.value = false;
  await loadFarms();
};

const cancel = () => {
  reload();
};

const validateStep = () => {
  return (
    (farm.value && farm.value.code) ||
    (newFarm.name.length > 0 &&
      newFarm.name.length <= 20 &&
      newFarm.city &&
      newFarm.state)
  );
};

const showNewFarmForm = () => {
  if (farm.value.code) {
    newFarm.name = "";
    newFarm.city = null;
    newFarm.state = null;
    showNewFarm.value = false;
  } else {
    showNewFarm.value = true;
  }
};

const save = async () => {
  if (
    farm.value === "Cadastrar uma nova fazenda..." ||
    farm.value.description === "Cadastrar uma nova fazenda..."
  ) {
    saveFarm();
  }
  const lastAccess = {
    code: uuid(),
    farm: newFarm.code || farm.value.code,
  };
  await db.lastAccessed.clear();
  await db.lastAccessed.add(lastAccess, lastAccess.code).catch((error) => {
    console.error("Error changing lastAccess to the database: ", error);
  });

  calendarNameStore.changeName(farm.value);
  await calendarRegisterStore.loadData();
  await calendarEventsStore.getEvents();
  reload();
  emit("close");
};

const saveFarm = async () => {
  const f = {
    code: uuid(),
    active: true,
    name: newFarm.name,
    city: newFarm.city,
    state: newFarm.state.uf,
    created: new Date(),
    changed: new Date(),
  };

  db.farm.add(f);
  farm = ref(null);
  farm.value = f;
  await loadFarms();
};

const cities = computed(() => {
  if (!newFarm.state) return [];

  for (let i = 0; i < brazil.states.length; i++) {
    if (brazil.states[i].name === newFarm.state.name) {
      return brazil.states[i].cities;
    }
  }

  return [];
});
</script>

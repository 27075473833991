<template>
  <div>
    <v-toolbar>
      <v-row align="center" justify="center" class="pa-0 ma-0">
        <v-col cols="5" sm="3" md="2" class="text-center py-0 px-2 ma-0">
          <v-menu
            v-model="initialDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                variant="outlined"
                hide-details
                density="compact"
                v-model="activitiesTimelineStore.computedInitialDateFormatted"
                label="Data de início"
                prepend-inner-icon="mdi-calendar"
                readonly
              >
              </v-text-field>
            </template>

            <v-date-picker
              locale="ptBR"
              v-model="initialDate"
              hide-header
              @update:modelValue="initialDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="5" sm="3" md="2" class="text-center py-0 px-2 ma-0">
          <v-menu
            v-model="finalDateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-bind="props"
                variant="outlined"
                hide-details
                density="compact"
                v-model="activitiesTimelineStore.computedFinalDateFormatted"
                label="Data de término"
                prepend-inner-icon="mdi-calendar"
                readonly
              >
              </v-text-field>
            </template>

            <v-date-picker
              locale="ptBR"
              v-model="finalDate"
              hide-header
              @update:modelValue="finalDateMenu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="1" class="text-center py-0 px-0 ma-0">
          <ActivitiesReportFilter />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row class="ma-0 pa-0" justify="center">
      <v-col v-for="item in timelineEvents" :key="item" cols="12" class="ma-0 pa-0">
        <v-timeline
          v-if="item.visible"
          line-thickness="3"
          :justify="smAndDown ? 'end' : 'center'"
          :side="smAndDown ? 'start' : item.branch == 'animal' ? 'end' : 'start'"
          :class="smAndDown ? 'pr-0' : item.branch == 'animal' ? 'pl-9' : 'pr-10'"
        >
          <v-timeline-item :dot-color="item.color" :icon="item.icon" fill-dot>
            <template v-slot:opposite>
              <strong> {{ item.start }} </strong>
              <div class="text-caption">
                Duração: {{ item.duration }}
                <span v-if="item.duration > 1"> dias </span>
                <span v-else> dia </span>
              </div>
            </template>
            <v-card density="compact" width="300px">
              <v-card-title
                class="text-wrap"
                :style="{
                  color: getTextColor(item.color),
                  'background-color': item.color,
                }"
              >
                {{ item.registers }}
              </v-card-title>
              <v-card-text class="mt-2">
                <strong> {{ item.category }} </strong>
                <p>{{ item.activity }}</p>
                <br />
                <p>{{ item.description }}</p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useDisplay } from "vuetify";
import { useDate } from "vuetify";
import ActivitiesReportFilter from "@/components/ActivitiesReportFilter.vue";
import { useActivitiesTimelineStore } from "@/stores/ActivitiesTimelineStore";
import { storeToRefs } from "pinia";

const { smAndDown } = useDisplay();

const activitiesTimelineStore = useActivitiesTimelineStore();
const { timelineEvents, initialDate, finalDate } = storeToRefs(activitiesTimelineStore);

const initialDateMenu = ref(false);
const finalDateMenu = ref(false);

const adapter = useDate();

onMounted(async () => {
  const year = new Date().getFullYear();
  initialDate.value = adapter.parseISO(`${year}-01-01`);
  finalDate.value = adapter.parseISO(`${year}-12-31`);
  await activitiesTimelineStore.getEvents(adapter);
});

watch([initialDate, finalDate], async () => {
  await activitiesTimelineStore.getEvents(adapter);
});

const getTextColor = (backgroundColor) => {
  if (!/^#?[0-9A-Fa-f]{6}$/.test(backgroundColor)) return "black";

  const hex = backgroundColor.replace("#", "");
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Fórmula para calcular brilho relativo
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Retorna preto ou branco com base no brilho
  return brightness > 128 ? "black" : "white";
};
</script>
